@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Sofia+Sans+Extra+Condensed:wght@100;200;300;400;600&family=Ubuntu:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;1,400&display=swap');

html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom: 170px;
}
footer {
 
  /* position: absolute;
  bottom: 0;
  width: 100%;
  height: 170px; */
}
.head{
  display:flex;
  justify-content: space-around; 
  margin: 0 auto;
  /* background-color: #c00; */
}
.title{

  font-family: 'Kanit', sans-serif;
  color:#333;
}
.imageCover{
 
  margin:0 auto;
  width:340px; 
}
.catImg{

margin: 0 auto;
  max-width:300px;
border: 1px solid #ccc;
}

.left__side_cate_list{
  margin-top:10px;
  margin-left:50px;
  font-size:25px;
  font-family: 'Montserrat', sans-serif;
  
}
.left__side_cate_link{
  text-decoration: none;
 color:#444;

}
.link{
  text-decoration: none;
 color:#444;

}

@media only screen and (max-width: 1200px) {
   
}

@media only screen and (max-width: 992px) {
   
}

@media only screen and (max-width: 768px) {
  .left__side_cate_list{
  
    margin:0 auto;
    font-size:25px;
       
  } 
}

@media only screen and (max-width: 576px) {
   
}

.r{background-color: #c00;}
.g{background-color: rgb(104, 213, 79);}
.o{background-color: rgb(204, 95, 0);}
.b{background-color: rgb(0, 65, 204);}
.w{background-color: rgb(129, 128, 128);}

.cw{ width: 300px; margin:10px;}

